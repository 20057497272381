import { Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';

export default function Intro() {
  const { translate } = useLocales();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Typography variant="h1">
        {String(translate('adminDashboard.main.title'))}
      </Typography>
      <Typography variant="subtitle1">
        {String(translate('adminDashboard.main.description'))}
      </Typography>
    </Stack>
  );
}
