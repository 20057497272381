import { Card, Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import { fSwissNumber } from '#/utils/formatNumber';
import { isColorTooDark } from '#/utils/cssStyles';

type Props = {
  title: string;
  value: string | number | undefined;
  color: string | undefined;
  percentage?: boolean;
};

export default function AnalyticCard({
  title,
  value,
  color,
  percentage,
}: Props) {
  const { translate } = useLocales();

  return (
    <Card
      sx={{
        px: 2,
        py: 11,
        backgroundColor: color,
        color: isColorTooDark(color) ? 'white' : 'black',

        // flex: '0 0 auto',
        margin: 2,
        width: {
          xs: '100%',
          md: 200,
        },
        height: 220,
      }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        // sx={{ color: '#091A7A' }}
      >
        <Stack direction="row" spacing={0.5}>
          <Typography variant="h4">
            {value ? fSwissNumber(value) : 0}
          </Typography>
          {percentage && <Typography variant="h4">%</Typography>}
        </Stack>
        <Typography variant="body2" textAlign="center">
          {String(translate(title))}
        </Typography>
      </Stack>
    </Card>
  );
}
