import { Navigate } from 'react-router-dom';
import React from 'react';
import MainDashboard from 'src/components/pages/Dashboard';
import Page from '#/components/shared/ui/Page';
import useAuth from '#/hooks/useAuth';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import PADashboard from '#/components/pages/PartnerAdminDashboard/PADashboard';
import LoadingScreen from '#/components/shared/ui/LoadingScreen';

export default function Dashboard() {
  const { user } = useAuth();
  const { currentAccount, isLoading } = useAccountSwitch();

  const partnerRole = user?.partner?.role;

  if (partnerRole === 'owner' || partnerRole === 'admin') {
    return <PADashboard />;
  }
  if (partnerRole === 'consultant') {
    return <Navigate to="/dashboard/berater" />;
  }

  if (isLoading && !currentAccount) {
    return <LoadingScreen />;
  }

  if (!user?.primary_account || localStorage.getItem('guest_on')) {
    return <Navigate to={`/dashboard/gast-bei/${currentAccount?.id}`} />;
  }

  return (
    <Page title="Dashboard">
      <MainDashboard />
    </Page>
  );
}
