import { Box } from '@mui/material';
import React from 'react';
import serviceCards from '#/components/pages/Dashboard/serviceCards';
import DashboardCard from '#/components/shared/dashboard-card';
import CardExtras from '#/components/pages/Dashboard/extras/card-extras';

export default function ServicesList() {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: 2,
      }}
    >
      {serviceCards.map((card, i) => (
        <DashboardCard
          key={`${card.title + i}`}
          title={card.title}
          icon={card.icon}
          description={card.description}
          actions={
            <CardExtras
              serviceKey={card.serviceKey as any}
              extras={{
                toServiceURL: card.url as string,
                progress: card.progressive,
              }}
            />
          }
        />
      ))}
    </Box>
  );
}
