import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroDescription from '#/components/pages/Dashboard/extras/intro-description';
import useLocales from '#/hooks/useLocales';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';
import useAuth from '#/hooks/useAuth';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';

export default function AdvisorsHelpers() {
  const { user } = useAuth();
  const { translate } = useLocales();
  const navigate = useNavigate();

  const [advisorType, setAdvisorType] = React.useState('financial' as string);
  const [showAdvisorsList, setShowAdvisorsList] = useState(false);

  const handleOpenAdvisorsList = () => setShowAdvisorsList(true);
  const handleCloseAdvisorsList = () => setShowAdvisorsList(false);

  const handleAdvisorsList = (type?: string) => {
    if (!type) return;
    setAdvisorType(type);
    handleOpenAdvisorsList();
  };

  const handleContinueProcess = () => {
    setShowAdvisorsList(false);
    const targetURL =
      advisorType === 'Finanz'
        ? 'https://finfinder.ch/?openModal=1&utm_source=gutgeregelt&utm_medium=app&utm_campaign=promo'
        : 'https://app.getyourlawyer.ch/gateway/consultancy?utm_source=gutgeregelt.ch&utm_medium=referral&utm_campaign=may2024';
    window.open(targetURL, '_blank');
    navigate('/dashboard/home');
  };

  const isPartner = user?.partner;
  const advisor = advisorType === 'Finanz' ? 'financial' : 'legal';

  return (
    <>
      <IntroDescription
        context={String(translate('global.homeDescription'))}
        targets={[
          String(translate('global.homeHighlight')),
          String(translate('global.homeHighlight2')),
        ]}
        onClick={handleAdvisorsList}
      />
      <ModalWrapper
        open={showAdvisorsList}
        onClose={handleCloseAdvisorsList}
        modalTitle={`global.${advisor}AdvisorsModal.title`}
        modalDescription={
          isPartner ? '' : `global.${advisor}AdvisorsModal.description`
        }
      >
        {isPartner ? (
          <AdvisorsListView
            advisorType={advisor}
            closeModal={handleCloseAdvisorsList}
          />
        ) : (
          <NonPartnerView
            advisorImage={
              advisor === 'financial'
                ? '/assets/images/finfinder_financial.svg'
                : '/assets/images/getyourlawyer_logo.svg'
            }
            isComplexForm={false}
            handleContinueProcess={handleContinueProcess}
            isFinancialAdvisor={advisor === 'financial'}
          />
        )}
      </ModalWrapper>
    </>
  );
}
